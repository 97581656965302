<template>
  <ServicesDescription :isPageWrapper="true" @connectService="connectService">
    <template #title>
      <h2>Услуга «Колл-трекинг»</h2>
    </template>
    <template #body>
      <p>
        Большая часть клиентов предпочитает звонить. <b>Коллтрекинг</b> рассчитает реальную
        окупаемость каналов рекламы, поможет оценить, какой из рекламных источников привел к
        продаже, а какой оказался не эффективным.
      </p>
      <div v-for="item in text" :key="item.title">
        <span>
          <b>
            {{ item.title }}
          </b>
        </span>
        <p>
          {{ item.text }}
        </p>
      </div>
      <p>
        Подключайте для рекламных кампаний только "чистые" номера, которые не использовались ранее,
        чтобы реально оценивать эффективность рекламных каналов.
      </p>
    </template>
  </ServicesDescription>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  data() {
    return {
      text: [
        {
          title: 'Узнайте, как работает Ваша реклама',
          text:
            'Отслеживайте звонки с различных источников. Узнайте, какая реклама дает целевые звонки, а какая тратит бюджет впустую.  '
        },
        {
          title: 'Повышайте эффективность рекламы',
          text:
            'Оптимизируйте рекламу на основе данных коллтрекинга. Превращайте затраты на продвижение в реальных клиентов.'
        },
        {
          title: 'Оптимизируйте затраты на рекламу',
          text:
            'Откажитесь от рекламы, которая не приносит эффект, и снижайте стоимость привлечения клиентов.'
        }
      ]
    };
  },

  components: {
    ServicesDescription
  },

  methods: {
    connectService() {
      this.$router.push({
        path: '/create-statement',
        query: { st: 'addService', service: 'колл-трекинг' }
      });
    }
  }
};
</script>

<style></style>
